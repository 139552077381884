import React, { useState } from 'react';
import { Parallax } from 'react-parallax';
import Layout from "../components/layouts";
import headerContact from "../images/header-contact.jpg";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch("https://prod-globalinheritance.herokuapp.com/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, message }),
    })
      .then((response) => {
        if (response.ok) {
          setIsSuccess(true);
          setIsError(false);
          setName("");
          setEmail("");
          setMessage("");
        } else {
          setIsSuccess(false);
          setIsError(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSuccess(false);
        setIsError(true);
      });
  };

  return (
    <Layout navBg>
      <div className="contact-page">
          <Parallax className="page-header" bgImage={headerContact} strength={400}>
              <div style={{height: '100vh', maxHeight: '550px'}}>
                  <div className="page-header-overlay" />
                  <h1 className="page-header-title white">CONTACT US</h1>
              </div>
          </Parallax>
          <div className="form-container application-form-container">
              <div className="form-inner application-form-inner">
                  <h1>Please fill out all fields.</h1>
                  <form name="contact-form" className="form contact-form" onSubmit={handleSubmit}>
                      <div className="form-field contact-field">
                          <input type="text" value={name} id="contact-form-name" onChange={(e) => setName(e.target.value)} />
                          <label>NAME</label>
                      </div>
                      <div className="form-field contact-field">
                          <input type="text" value={email} id="contact-form-mail" onChange={(e) => setEmail(e.target.value)} />
                          <label>EMAIL</label>
                      </div>
                      <div className="form-field contact-field">
                          <textarea id="contact-form-message" value={message} onChange={(e) => setMessage(e.target.value)} ></textarea>
                          <label>MESSAGE</label>
                      </div>

                      <div className="submit-container">
                          <button type="submit" className="btn-container">
                              <div className="btn large primary">
                                  <span>Submit</span>
                              </div>
                          </button>
                      </div>
                  </form>
                  {isSuccess && <p>Email sent successfully!</p>}
                  {isError && <p>There was an error sending your email. Please try again later.</p>}
              </div>
          </div>
        
      </div>
    </Layout>
);
}

export default Contact;
